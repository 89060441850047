import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../organisatieeffectiviteit.scss"
import kantoor from "../../images/zipper-coaching-image.png"
import bgcase from "../../images/certi.jpg"

const Coachopleiding = () => {
  return (
    <Layout>
      <SEO
        title="Zipperr® Coachopleiding"
        description="Wil jij een erkende Zipperr® Coach worden? 
          Deze professionele en erkende coachopleiding wordt gegeven in de
          Zipperr®Academie, ons trainingscentrum in Nuenen, Noord Brabant."
      />
      <div
        style={{
          width: "100vw",
          height: "250px",
          overflow: "hidden",
          backgroundImage: `url(${kantoor})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/*<img style={{width: '100vw'}} src={office} alt=""/>*/}
      </div>
      <div className="highlightedText containerPadModified">
        {/*<div className="tiny-header centerText">Breinkracht organisatieadvies op basis van de Zipperr® Methode</div>*/}
        <div className="heading">
          <div>
            <h1 className={"subTitleContent centerText"}>
              Volg de Zipperr® Coachopleiding van onze Zipperr® Academie
            </h1>
          </div>
        </div>
        <div className="subhead">
          <p className=" afterlife">Creëren van balans</p>
        </div>
        <p>
          Zipperr® maakt gebruik van systemisch werk, een beproefde methode voor
          het verkrijgen van inzicht in onderlinge relaties tussen mensen, en
          creëren van balans in die relaties en ReAttach, een nieuwe interventie
          die is gericht op prikkelverwerking, waarmee gebruikers overzicht en
          controle over hun emoties en hun leven krijgen en die ook toepasbaar
          is op emoties in de sport en op de werkvloer. Vriendelijk,
          toegankelijk en effectief!
        </p>
      </div>

      <div className={"containerPad flexy"}>
        <div className={"half block contntr"}>
          <h2>Wil jij een erkende Zipperr® Coach worden? </h2>
          <p>
            Ben jij ook een coach die die zich geroepen voelt om mentale
            veerkracht in organisaties te verhogen en dit te doen door het
            persoonlijk begeleiden van medewerkers met de Zipperr®Methode, dan
            kun je hier meer informatie vinden om erkende Zipperr®Coach en je
            aan te sluiten bij de Zipperr®Organisatie. Je bent een ZZPér, hebt 3
            tot 5 jaar ervaring in het coachen, bent aangesloten bij een
            beroepsvereniging en hebt een beroepsaansprakelijkheidsverzekering.
            Om erkend Zipperr®Coach te worden dien je drie opleidingen te
            doorlopen.
          </p>
          <p>Dit zijn de drie elementen uit de Zipperr®Methode:</p>
          <ul>
            <li>De ReAttach®Methode</li>
            <li>De Zipperr®Case</li>
            <li>De Enneagram Zipperr style</li>
          </ul>
          <p className={"btn-container"}>
            <Link
              to={"/contact"}
              className="btn primary-lg round expand-on-hover"
            >
              Aanmelden of meer informatie
            </Link>
          </p>
        </div>
        <div
          className={"half block imger"}
          style={{
            overflow: "hidden",
            backgroundImage: `url(${bgcase})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImageMobile: file(relativePath: { eq: "briefcase.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    placeholderImageLaptop: file(relativePath: { eq: "case-edit.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 600, height: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    placeholderImageDesktop: file(relativePath: { eq: "case-edit.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 1200, height: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ziperCaseImg: file(relativePath: { eq: "ad-img.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default Coachopleiding
